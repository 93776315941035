
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index1UbUUHJnTQKeNz90cSp2qt6ZeX09IVuhUb0EKDPqkCgMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/index.vue?macro=true";
import { default as indexhmFEG9_2_sWtSw1q_oqAZPItlqZxFWEvEkPZi9P1x_YMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startnRT3o7URHmfZop6pqel5PGHGX6DRc54hXDzELyqifakMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startD_45obA6JoRqTQH8HDZdtJmfqOXjnsWpZ_45HJ42doa5Mt0Meta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutGVpZgcLr2uZ9HG9nlxcUlyhuEP3Eq_wMLa7Igmk7peAMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koJoG_45lMHWBb_45L_453hCID9j2D1yUkh3wCbOyFOcoDs_TH0Meta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationdNHDP6yBCxvb8Rf_ca_45uBOxcaRRJB39s97JAcX4NjmsMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationNiovSvZgirAlTN7b0ScHRXfn67lClsE_A0_YoLNzx_QMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerD0I_45ROfmpQn6BnX2VO5rWibiUf5aWxon4ybzOqJ0gM4Meta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyer_45pMqPxoKt9B_45PEpCn8lW1Dw778dfrZ51aJYUY3B3fWcMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentVsFLxbg0Win_f5auykqsqkhhpTDkmEzZNGOBeptuVQUMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeSrhA8gZ6skPmQvngNMxFz_45lRWySRu1jWBpONQWKFMToMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as payment5zKvsnEthFNWnowqETkMxfjWDySJhZF5iVaR9dhOup8Meta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta } from "/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: index1UbUUHJnTQKeNz90cSp2qt6ZeX09IVuhUb0EKDPqkCgMeta?.name ?? "index",
    path: index1UbUUHJnTQKeNz90cSp2qt6ZeX09IVuhUb0EKDPqkCgMeta?.path ?? "/",
    props: index1UbUUHJnTQKeNz90cSp2qt6ZeX09IVuhUb0EKDPqkCgMeta?.props ?? false,
    meta: index1UbUUHJnTQKeNz90cSp2qt6ZeX09IVuhUb0EKDPqkCgMeta || {},
    alias: index1UbUUHJnTQKeNz90cSp2qt6ZeX09IVuhUb0EKDPqkCgMeta?.alias || [],
    redirect: index1UbUUHJnTQKeNz90cSp2qt6ZeX09IVuhUb0EKDPqkCgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/index.vue")
  },
  {
    name: indexhmFEG9_2_sWtSw1q_oqAZPItlqZxFWEvEkPZi9P1x_YMeta?.name ?? "lang",
    path: indexhmFEG9_2_sWtSw1q_oqAZPItlqZxFWEvEkPZi9P1x_YMeta?.path ?? "/:lang?",
    props: indexhmFEG9_2_sWtSw1q_oqAZPItlqZxFWEvEkPZi9P1x_YMeta?.props ?? false,
    meta: indexhmFEG9_2_sWtSw1q_oqAZPItlqZxFWEvEkPZi9P1x_YMeta || {},
    alias: indexhmFEG9_2_sWtSw1q_oqAZPItlqZxFWEvEkPZi9P1x_YMeta?.alias || [],
    redirect: indexhmFEG9_2_sWtSw1q_oqAZPItlqZxFWEvEkPZi9P1x_YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startnRT3o7URHmfZop6pqel5PGHGX6DRc54hXDzELyqifakMeta?.name ?? "lang-voucher-start",
    path: startnRT3o7URHmfZop6pqel5PGHGX6DRc54hXDzELyqifakMeta?.path ?? "/:lang?/voucher/start",
    props: startnRT3o7URHmfZop6pqel5PGHGX6DRc54hXDzELyqifakMeta?.props ?? false,
    meta: startnRT3o7URHmfZop6pqel5PGHGX6DRc54hXDzELyqifakMeta || {},
    alias: startnRT3o7URHmfZop6pqel5PGHGX6DRc54hXDzELyqifakMeta?.alias || [],
    redirect: startnRT3o7URHmfZop6pqel5PGHGX6DRc54hXDzELyqifakMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startD_45obA6JoRqTQH8HDZdtJmfqOXjnsWpZ_45HJ42doa5Mt0Meta?.name ?? "lang-purchase-start",
    path: startD_45obA6JoRqTQH8HDZdtJmfqOXjnsWpZ_45HJ42doa5Mt0Meta?.path ?? "/:lang?/purchase/start",
    props: startD_45obA6JoRqTQH8HDZdtJmfqOXjnsWpZ_45HJ42doa5Mt0Meta?.props ?? false,
    meta: startD_45obA6JoRqTQH8HDZdtJmfqOXjnsWpZ_45HJ42doa5Mt0Meta || {},
    alias: startD_45obA6JoRqTQH8HDZdtJmfqOXjnsWpZ_45HJ42doa5Mt0Meta?.alias || [],
    redirect: startD_45obA6JoRqTQH8HDZdtJmfqOXjnsWpZ_45HJ42doa5Mt0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutGVpZgcLr2uZ9HG9nlxcUlyhuEP3Eq_wMLa7Igmk7peAMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutGVpZgcLr2uZ9HG9nlxcUlyhuEP3Eq_wMLa7Igmk7peAMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutGVpZgcLr2uZ9HG9nlxcUlyhuEP3Eq_wMLa7Igmk7peAMeta?.props ?? false,
    meta: checkoutGVpZgcLr2uZ9HG9nlxcUlyhuEP3Eq_wMLa7Igmk7peAMeta || {},
    alias: checkoutGVpZgcLr2uZ9HG9nlxcUlyhuEP3Eq_wMLa7Igmk7peAMeta?.alias || [],
    redirect: checkoutGVpZgcLr2uZ9HG9nlxcUlyhuEP3Eq_wMLa7Igmk7peAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koJoG_45lMHWBb_45L_453hCID9j2D1yUkh3wCbOyFOcoDs_TH0Meta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koJoG_45lMHWBb_45L_453hCID9j2D1yUkh3wCbOyFOcoDs_TH0Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koJoG_45lMHWBb_45L_453hCID9j2D1yUkh3wCbOyFOcoDs_TH0Meta?.props ?? false,
    meta: koJoG_45lMHWBb_45L_453hCID9j2D1yUkh3wCbOyFOcoDs_TH0Meta || {},
    alias: koJoG_45lMHWBb_45L_453hCID9j2D1yUkh3wCbOyFOcoDs_TH0Meta?.alias || [],
    redirect: koJoG_45lMHWBb_45L_453hCID9j2D1yUkh3wCbOyFOcoDs_TH0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationdNHDP6yBCxvb8Rf_ca_45uBOxcaRRJB39s97JAcX4NjmsMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationdNHDP6yBCxvb8Rf_ca_45uBOxcaRRJB39s97JAcX4NjmsMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationdNHDP6yBCxvb8Rf_ca_45uBOxcaRRJB39s97JAcX4NjmsMeta?.props ?? false,
    meta: confirmationdNHDP6yBCxvb8Rf_ca_45uBOxcaRRJB39s97JAcX4NjmsMeta || {},
    alias: confirmationdNHDP6yBCxvb8Rf_ca_45uBOxcaRRJB39s97JAcX4NjmsMeta?.alias || [],
    redirect: confirmationdNHDP6yBCxvb8Rf_ca_45uBOxcaRRJB39s97JAcX4NjmsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationNiovSvZgirAlTN7b0ScHRXfn67lClsE_A0_YoLNzx_QMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationNiovSvZgirAlTN7b0ScHRXfn67lClsE_A0_YoLNzx_QMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationNiovSvZgirAlTN7b0ScHRXfn67lClsE_A0_YoLNzx_QMeta?.props ?? false,
    meta: confirmationNiovSvZgirAlTN7b0ScHRXfn67lClsE_A0_YoLNzx_QMeta || {},
    alias: confirmationNiovSvZgirAlTN7b0ScHRXfn67lClsE_A0_YoLNzx_QMeta?.alias || [],
    redirect: confirmationNiovSvZgirAlTN7b0ScHRXfn67lClsE_A0_YoLNzx_QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerD0I_45ROfmpQn6BnX2VO5rWibiUf5aWxon4ybzOqJ0gM4Meta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerD0I_45ROfmpQn6BnX2VO5rWibiUf5aWxon4ybzOqJ0gM4Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerD0I_45ROfmpQn6BnX2VO5rWibiUf5aWxon4ybzOqJ0gM4Meta?.props ?? false,
    meta: buyerD0I_45ROfmpQn6BnX2VO5rWibiUf5aWxon4ybzOqJ0gM4Meta || {},
    alias: buyerD0I_45ROfmpQn6BnX2VO5rWibiUf5aWxon4ybzOqJ0gM4Meta?.alias || [],
    redirect: buyerD0I_45ROfmpQn6BnX2VO5rWibiUf5aWxon4ybzOqJ0gM4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyer_45pMqPxoKt9B_45PEpCn8lW1Dw778dfrZ51aJYUY3B3fWcMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyer_45pMqPxoKt9B_45PEpCn8lW1Dw778dfrZ51aJYUY3B3fWcMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyer_45pMqPxoKt9B_45PEpCn8lW1Dw778dfrZ51aJYUY3B3fWcMeta?.props ?? false,
    meta: buyer_45pMqPxoKt9B_45PEpCn8lW1Dw778dfrZ51aJYUY3B3fWcMeta || {},
    alias: buyer_45pMqPxoKt9B_45PEpCn8lW1Dw778dfrZ51aJYUY3B3fWcMeta?.alias || [],
    redirect: buyer_45pMqPxoKt9B_45PEpCn8lW1Dw778dfrZ51aJYUY3B3fWcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentVsFLxbg0Win_f5auykqsqkhhpTDkmEzZNGOBeptuVQUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentVsFLxbg0Win_f5auykqsqkhhpTDkmEzZNGOBeptuVQUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentVsFLxbg0Win_f5auykqsqkhhpTDkmEzZNGOBeptuVQUMeta?.props ?? false,
    meta: paymentVsFLxbg0Win_f5auykqsqkhhpTDkmEzZNGOBeptuVQUMeta || {},
    alias: paymentVsFLxbg0Win_f5auykqsqkhhpTDkmEzZNGOBeptuVQUMeta?.alias || [],
    redirect: paymentVsFLxbg0Win_f5auykqsqkhhpTDkmEzZNGOBeptuVQUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeSrhA8gZ6skPmQvngNMxFz_45lRWySRu1jWBpONQWKFMToMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeSrhA8gZ6skPmQvngNMxFz_45lRWySRu1jWBpONQWKFMToMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeSrhA8gZ6skPmQvngNMxFz_45lRWySRu1jWBpONQWKFMToMeta?.props ?? false,
    meta: challengeSrhA8gZ6skPmQvngNMxFz_45lRWySRu1jWBpONQWKFMToMeta || {},
    alias: challengeSrhA8gZ6skPmQvngNMxFz_45lRWySRu1jWBpONQWKFMToMeta?.alias || [],
    redirect: challengeSrhA8gZ6skPmQvngNMxFz_45lRWySRu1jWBpONQWKFMToMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: payment5zKvsnEthFNWnowqETkMxfjWDySJhZF5iVaR9dhOup8Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: payment5zKvsnEthFNWnowqETkMxfjWDySJhZF5iVaR9dhOup8Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: payment5zKvsnEthFNWnowqETkMxfjWDySJhZF5iVaR9dhOup8Meta?.props ?? false,
    meta: payment5zKvsnEthFNWnowqETkMxfjWDySJhZF5iVaR9dhOup8Meta || {},
    alias: payment5zKvsnEthFNWnowqETkMxfjWDySJhZF5iVaR9dhOup8Meta?.alias || [],
    redirect: payment5zKvsnEthFNWnowqETkMxfjWDySJhZF5iVaR9dhOup8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/alicante/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/altea/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/benidorm/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/calpe/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/denia/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/l-alfas-del-pi/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/la-nucia/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/mutxamel/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/rojales/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/alicante/teulada/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/arenys-de-mar/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/barcelona/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/berga/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 16",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/caldes-de-montbui/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 17",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/calella/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 18",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/la-garriga/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 19",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/masquefa/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 20",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/mataro/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 21",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/sant-boi-de-llobregat/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 22",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/sant-esteve-sesrovires/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 23",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/sant-joan-despi/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 24",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/seva/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 25",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/sitges/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 26",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/barcelona/vallromanes/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 27",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 28",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/caldes-de-malavella/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 29",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/castello-dempuries/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 30",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/girona/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 31",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/llanars/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 32",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/lloret-de-mar/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 33",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/sant-feliu-de-guixols/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 34",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/torrent/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 35",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/girona/toses/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 36",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/gran-canaria/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 37",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/gran-canaria/agaete/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 38",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/gran-canaria/las-palmas-de-gran-canaria/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 39",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/gran-canaria/maspalomas/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 40",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/gran-canaria/mogan/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 41",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/gran-canaria/san-agustin-maspalomas/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 42",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/gran-canaria/san-bartolome-de-tirajana/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 43",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/gran-canaria/telde/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 44",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 45",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/benalmadena/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 46",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/estepona/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 47",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/fuengirola/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 48",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/malaga/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 49",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/marbella/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 50",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/mijas/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 51",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/ronda/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 52",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/malaga/torremolinos/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 53",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 54",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/adeje/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 55",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/arona/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 56",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/guia-de-isora/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 57",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/playa-de-las-americas/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 58",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/puerto-de-la-cruz/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 59",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/san-cristobal-de-la-laguna/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 60",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/san-miguel-de-abona/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 61",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/santa-cruz-de-tenerife/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 62",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/tenerife/santa-ursula/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 63",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/valladolid/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 64",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/valladolid/olmedo/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 65",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/valladolid/penafiel/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 66",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/valladolid/san-bernarndo/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.name ?? "Spa by location landing: 67",
    path: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.path ?? "/spa-balneario/valladolid/valladolid/",
    props: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.props ?? false,
    meta: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta || {},
    alias: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.alias || [],
    redirect: SpaList_46pageWpxtgawkdMXKAEH53nlODqDKuiJlsyeRh0KLDJ356KAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 4",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 5",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 6",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 7",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 8",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 9",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 10",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 11",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 12",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/higueron-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 13",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 14",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/shama-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 15",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 16",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 17",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 18",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 19",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 20",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 21",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 22",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 23",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 24",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 25",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 26",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 27",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 28",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 29",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 30",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 31",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 32",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 33",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 34",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 35",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 36",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 37",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/luxor-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 38",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/inner-flow/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 39",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 40",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 41",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 42",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 43",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 44",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 45",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 46",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 47",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/calma-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 48",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 49",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 50",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 51",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 52",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 53",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 54",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 55",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 56",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 57",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 58",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 59",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/alum-spa-maestranza/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 60",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 61",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 62",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 63",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 64",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 65",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 66",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/spa-paradisus/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 67",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/girona/spa-boutique-hotel-comtal-empuries/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 68",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 69",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 70",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 71",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 72",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 73",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 74",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 75",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 76",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 77",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 78",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 79",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 80",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 81",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 82",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 83",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 84",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/hydros-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 85",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 86",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 87",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 88",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 89",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 90",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 91",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 92",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 93",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 94",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/malaga/mood-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 95",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 96",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 97",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 98",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 99",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 100",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 101",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 102",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 103",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 104",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 105",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/girona/spa-la-collada/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 106",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 107",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 108",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 109",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 110",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 111",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.name ?? "Spa landing: 112",
    path: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/",
    props: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.props ?? false,
    meta: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta || {},
    alias: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.alias || [],
    redirect: SpaDetail_46pagei5y7oXadn68h2DUVzCsXARfRovkuukRuXQ9zvB8RrUEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/maderoterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/masaje-de-espalda-y-circuito-spa-para-dos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/circuito-spa-para-dos-2-horas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/circuito-spa-1-hora/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/masaje-deportivo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/masaje-piedras-volcanicas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/masaje-de-espalda-y-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/reflexologia-podal-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/masaje-antiestres-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/masaje-oriental-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/masaje-aromatico-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/tratamiento-kobido-antiedad-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/beauty-pack-de-pies-y-manos-degustacion-de-tes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/paquete-romantico-masaje-spa-almuerzo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/masaje-relajante-en-pareja-con-cava-chocolate-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/la-brise-spa/masaje-wellness-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-ritual-115-min-con-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-antiestres-aromatico-45-min-con-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/tratamiento-facial-hidraforce-source-marine-55min-con-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/serenity-love/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/family-day-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-massage-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/gastrospa-lunes-a-viernes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-individual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/stone-therapy-55-con-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/sprunch-con-circuito-termal-sabados-y-domingos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/lomi-lomi-nui-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-antiestres-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/after-work-30-min-masaje-con-circuito-termal-lunes-a-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/senses-night/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/manicura-y-pedicura-express-con-acceso-a-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/iberostar-vegan-facial-55-min-con-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/brunch-sin-circuito-termal-sabados-y-domingos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-viernes-domingo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-lunes-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-lunes-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-cava-viernes-domingo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-50-min-desayuno-viernes-domingo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-lunes-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-desayuno-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-cava-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-relajante-25-min-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-terapeutico-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-masaje-deportivo-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-viernes-domingo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/circuito-spa-menu-sofia-bar-tapas-para-2-personas-lunes-jueves/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/indian-ritual-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/experiencia-relax-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/bienestar-citrus-relax-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/spa-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/spa-privado-con-masaje-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/momento-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/daypass-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-deep-tisue-20-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-hydraluronic/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/viaje-a-la-provenza/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-relajante-20-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-menores-4-a-17anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-placido-sueno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/reina-de-egipto-circuito/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/circuito-termal-1h30min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-buffet-restaurante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-45/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/antiestres-25/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-para-dos-masaje-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-para-dos-masaje-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/pack-armonia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/chocolaterapia-masaje-consecutivo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/masaje-karite-55/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-25-buffet-masaje-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet-masaje-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-berga-resort/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/envoltura-de-barro-autocalentador/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/masaje-aromatico-con-exfoliacion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/diamond-energy/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/love-bliss/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/chocolate-bliss/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/hot-stone-harmony/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/aromatherapy-power/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/citrus-power/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/peeling-corporal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/ritual-spa-privado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-wellbeing-alabriga/circuito-de-aguas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-relajante-de-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-aromatico-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/piel-de-seda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/body-wrap/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/terapia-geotermal-con-piedras/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-relajante-de-pies/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/el-arte-de-crear/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-dinamico-de-recuperacion-corporal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-terapeutico-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/tratamiento-de-limpieza-facial-purificante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/peeling-corporal-con-cristales-marinos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/ritual-anti-stress/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-berga-resort/escapada-parejas-estancia-masaje-spa-piscina-fitness/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/prenatal-y-posnatal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/esencias-y-sentidos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/chocolate/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/barro/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-podal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-corporal-ayurveda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/hydraluronic/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-hawaiano/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-adulto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/baneras-de-inmersion-en-hielo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-platinum-beach/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/tarjeta-regalo-importe-200e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-masaje-deportivo-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-circuito-spa-con-cava-y-bombones/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-presoterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-relax-unica-spa-masaje-relax-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-80/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-masaje-deportivo-25-min-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-romantico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-hidratacion-profunda-spa-tratamiento-facial-de-acido-hialuronico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-relajante-aromatico-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-relajante-aromatico-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/facial-anti-aging-de-acido-hialuronico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/antiestres-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-spa-10-sesiones/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/antiestres-masaje-doble-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/anthelia-signature-massage-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-hydrowellness-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/oriental-55-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/especifico-localizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-almuerzo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-masaje-almuerzo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/spa-en-familia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/sprunch-spa-brunch/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/momento-spa-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-bodycare/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-skincare/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-care-para-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-eternal-love-para-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/spa-privado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-descontacturante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-cielo-y-tierra/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-lomi-lomi/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-sensualidad/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/ritual-de-la-alegria/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-antiestres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/kids-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-2-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/circuito-de-aguas-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/gourmet-love-para-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/shama-gourmet-para-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico-con-pindas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-craneofacial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-especial-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-lomi-lomi-con-aceite-de-coco/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/serenidad-citrica/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/circuito-spa-experiencia-gastronomica-para-dos-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-especial-embarazadas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-relajacion-general/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/tesoro-de-bienestar/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/rio-ter/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/circuito-spa-masaje-para-dos-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-a-4-manos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-con-piedras-calientes-y-frias/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-de-tierra-y-cielo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/masaje-saba/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/galletas-del-valle-de-camprodon/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-hotel-grevol/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/circuito-spa-acceso-gimnasio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-relajante-aromaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/peeling-exfoliante-corporal-piel-de-seda-40mins/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/pack-bienestar-para-dos-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-completo-muscular-profundo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-terapeutico-espalda-cuello-y-hombro/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-personalizado-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/drenaje-linfatico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-personalizado-25/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/piel-de-seda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/reflexologia-podal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/spa-relax-2-horas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/circuito-thalassa-1-hora/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/ritual-vincci-sun/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-40-min-y-spa-2-horas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-20-min-y-spa-2-horas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-celta-de-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-abhyanga/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/bronceado-rapido-y-duradero/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/sweet-night-en-be-aloe/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-canario/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/night-experience/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/vitamina-c-tratamiento-lifting/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/chocolaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/serenity-eco-masaje-antiestres-con-aceite-100-natural/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/regala-a-papa-un-dia-de-relajacion-y-bienestar-en-be-aloe/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-canario-islas-canarias-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/piscina-aloe-cena/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/piscina-be-aloe-comida/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/sublime-and-personalized-sensory-experience/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/circuito-be-aloe-wellness/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/bono-be-aloe-welness-para-dos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-futura-mama/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-wellness-yoga-y-desayuno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-local-o-craneo-facial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/tratamiento-mousse-de-chocolate/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/marine-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/albir-relax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-con-canas-de-bambu/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/senses-detox/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-aromaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/vip-senses/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-relajante-general/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-martes-a-viernes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-martes-a-viernes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-geotermal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/glow-flow-spa-ritual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/deep-tissue-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/digital-wellness-escape/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/aromatherapy-back-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/rejuvenate/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/balmoral-signature-ritual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/time-ritual-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/antistress-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/despertar-ritual-de-relajacion-masaje-y-desayuno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-gold-edition/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/glow-recovery-facial-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/organic-signature-facial-by-scens-40-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-65-minutos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-gold-edition/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-silver-edition/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-silver-edition/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desconecta-para-conectar/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/cuidado-maternal-65min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-25min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-de-25-minutos-infantil/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/escapada-relax-foodie-spa-menu-almuerzo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-40-minutos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/bono-regalo-150e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-espalda-cuello-y-hombros/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/bono-regalo-100e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/bono-regalo-200e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/bono-regalo-300e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/bono-regalo-50e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/circuito-spa-masaje-50para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/romance-spa-privado-para-2-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/ritual-parque-cristobal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-a-su-eleccion-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-relax-futuras-mamas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/jelly-beans-masaje-para-ninos-y-ninas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/paquete-mi-papa-y-yo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/pack-relax-spa-masaje-de-espalda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/circuito-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/experiencia-spa-para-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/paquete-mi-mama-y-yo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-relax-de-rostro-cuello-y-escote-beer-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun-pareja-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-25-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-55-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/de-este-a-oeste/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-anantara/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/tiempo-para-dos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/oasis-de-serenidad/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-tradicional-tailandes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-muscular-profundo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/dulce-espera/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/facial-personalizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/experiencia-anantara-con-masaje-y-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/viaje-a-traves-de-los-sentidos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/circuito-spa-residente-canario/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-familiar-mascarilla-facial-solidaria/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/silhoutte-perfection-massage-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/hot-stone-bliss-90/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/deep-tissue-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/exfoliation-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/serenity-full-body-massage-80-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/digital-wellness-escape/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/time-ritual-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/melia-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/antiestres-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/aromatherapy-back-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/sir-victor-exclusive-signature-ritual-by-serena-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/eternal-youth/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/exfoliation/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/deep-tissue-massage-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/slow-glow-masaje-y-circuito-spa-con-copa-de-cava-o-te/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/cityscape-spa-masaje-y-gastronomia-en-el-rooftop/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-circuito-spa-30-y-masaje-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/despertar-by-serena-spa-desayuno-y-circuito-spa-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/queen-of-egypt-by-alqvimia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-serena-spa-luxury-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/white-light/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/serenity-full-body-massage-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-couple-cocoon-signature-ritual-y-spa-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/beauty-party-spa-privado-90/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/hot-stone-bliss/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/entrada-spa-acceso-general-circuito-de-aguas-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/firm-and-tone-sculpting-treatment/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/digital-wellness/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/melia-exclusive-signature-ritual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/anti-stress-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/time-ritual-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/aromatherapy-back-massage-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 376",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/deep-tissue-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 377",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/exfoliation/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 378",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/couple-cocoon-by-serena-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 379",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/limpieza-profunda-con-hidromass/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 380",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/higiene-facial-expres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 381",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-chocolaterapia-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 382",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-lirio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 383",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-jade/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 384",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-relajante-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 385",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/exfoliacion-corporal-hidratacion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 386",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-orquidea/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 387",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-piedras-calientes-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 388",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-descontracturante-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 389",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-loto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 390",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/facial-especializado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 391",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-ayurveda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 392",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 393",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-anti-dolor-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 394",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-lomi-lomi-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 395",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-en-pareja-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 396",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-masaje-aromaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 397",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-aromaterapia-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 398",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-aromaterapia-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 399",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/reflexologia-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 400",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/facial-express/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 401",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-y-moet-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 402",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-muscular-profundo-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 403",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-piernas-cansadas-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 404",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-craneal-de-la-india/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 405",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 406",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-tour-el-regalo-perfecto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 407",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/mediterranean-flavours-90/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 408",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/signature-journey/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 409",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/sweet-orange-body-scrub/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 410",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rejuvenate-60/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 411",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 412",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/digital-wellness-escape-head-neck-and-shoulder-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 413",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/serenity-full-body-massage-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 414",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/despertar-by-serena-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 415",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/aromaterapia-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 416",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/time-ritual-60/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 417",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/deep-tissue-massage-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 418",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/experiencia-gastro-wellness-spa-50-con-opcion-masaje-y-cena-para-2-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 419",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/acceso-spa-circuito-aguas-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 420",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-de-pindas-con-extracto-de-cerveza-completo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 421",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 422",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 423",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-1-persona/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 424",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 425",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 426",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 427",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 428",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 429",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 430",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 431",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-relax-de-rostro-cuello-y-escote-beer/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 432",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-holistico-con-aceite-de-cerveza-parcial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 433",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-beauty/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 434",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 435",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 436",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/manicura-shellac/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 437",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/tinte-de-pestanas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 438",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/daypass-circuito-spa-almuerzo-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 439",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/tinte-de-cejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 440",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/facial-balance/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 441",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/santa-agueda-secret-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 442",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/circuito-spa-masaje-40-minutos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 443",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/circuito-spa-ritual-reino-de-hungria/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 444",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/reino-de-hungria-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 445",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/beauty-flash/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 446",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/cordial-antiestres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 447",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/relajante-completo-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 448",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/relajante-parcial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 449",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/circuito-spa-facial-aqua/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 450",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/entrada-circuito-externo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 451",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/perchel-magic-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 452",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/daypass-circuito-spa-almuerzo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 453",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/callao-dream-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 454",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/masaje-con-piedras-calientes-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 455",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/masaje-balines-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 456",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/terapeutico-completo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 457",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/terapeutico-parcial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 458",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/ritual-facial-y-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 459",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-ritual-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 460",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-masaje-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 461",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 462",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 463",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 464",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 465",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-relax-con-vela-aromatica-de-75-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 466",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/ritual-beauty-tratamiento-facial-55/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 467",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-con-vela-circuito-spa-90min-masaje-relajante-con-vela-aromatica-de-75min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 468",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-serenity-circuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 469",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-buenos-dias-desayuno-buffet-circuito-spa-90min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 470",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-gastro-relax-comida-cena-agua-y-vino-de-la-casa-incl-circuito-spa90min-buffet-o-menu-segun-ocupacion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 471",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 472",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-renovadora-circuito-spa-de-90min-limpieza-facial-basica-masaje-orient-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 473",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-supreme-minimo-2-personas-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 474",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 475",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-supreme-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 476",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-envolvente-circuito-spa-90min-ritual-corporal-con-exfoliacion-y-envolturalimpieza-facial-basica/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 477",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-serenitycircuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 478",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-min-2-personas-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 479",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 480",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 481",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 482",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 483",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-spa-de-90min-a-partir-de-15-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 484",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 485",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-holistico-de-piedras-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 486",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/hidratante-hialuronico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 487",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/firmeza/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 488",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/duo-reafirmante-facial-y-corporal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 489",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-relajante-aromatico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 490",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 491",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/despayuno-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 492",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-de-aguas-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 493",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-buffet-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 494",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-almuerzo-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 495",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-privado-para-2-90/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 496",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-con-spa-privado-para-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 497",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-cena-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 498",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-almuerzo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 499",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/classic-spa-circuito-de-aguas-90/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 500",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/escapada-romantica-privada-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 501",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-cena/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 502",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-spa-brunch-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 503",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 504",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/despayuno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 505",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-en-familia-para-3-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 506",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/evasion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 507",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-muscular-profundo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 508",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-personalizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 509",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-localizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 510",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-canario/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 511",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-exclusive-spa-privado-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 512",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/futura-mama-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 513",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-luxury-sunrise-desayuno-buffet-circuito-termal-masaje-aromaterapia-40min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 514",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sri-lanka-30min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 515",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-zen-circuito-termal-60min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 516",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-celebrate-spa-privado-grupos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 517",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-deluxe-circuito-termal-120min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 518",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/2x1-spazio-scape-circuito-termal-60min-lunes-a-viernes-11h-15h/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 519",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/peeling-con-hidratacion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 520",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/aromaterapia-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 521",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/age-defense-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 522",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 523",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/ocean-miracle-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 524",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/sensations-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 525",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/higiene-activa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 526",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/cacao-tamakuru-90min-spazio-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 527",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/mediterranean-bliss-120-spazio-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 528",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/infinity-60min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 529",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/twin-bombay-80min-spazio-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 530",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/secretos-del-nilo-90min-spazio-para-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 531",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/deep-tissue-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 532",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/bambu-detox-80min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 533",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-lovers-circuito-termal-1h-masaje-cacao-tamakuru-40min-para-dos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 534",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/spazio-sunrise-desayuno-buffet-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 535",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-wellness/holistico-del-tibet-90min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 536",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-individual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 537",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/tratamiento-facial-antioxidante-de-vitamina-c/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 538",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-individual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 539",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 540",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 541",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/chocolaterapia-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 542",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje-y-cena/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 543",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 544",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet-con-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 545",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-cena/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 546",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 547",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-cena-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 548",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/circuito-spa-clientes-externos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 549",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 550",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/luxor-spa/experiencia-en-jaccuzi-individual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 551",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/inner-flow/dia-en-inner-flow/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 552",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/inner-flow/bienvenido-a-tu-primera-experiencia-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 553",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 554",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-vez-primer-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 555",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/inner-flow/primera-experiencia-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 556",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/inner-flow/spa-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 557",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-buffet-sin-prisas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 558",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-cava-x-2-todo-burbujas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 559",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-aloe-piel-sana/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 560",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-terra-bienestar/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 561",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/deluxe-day-tu-daypass-de-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 562",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-moet-x-2-un-capricho/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 563",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-tapas-pequenos-sabores/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 564",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 565",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-neurosedante-con-aromaterapia-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 566",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-colageno-marino-ojos-incluidos-natura-bisse/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 567",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-lifting-alisa-rellena-tensa-silicium-marino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 568",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-cocoon-facial-anti-polucion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 569",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-hyaluronico-marino-anti-edad-activador-de-juventud/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 570",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/bamboo-scrub-peeling-hidratacion-corporal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 571",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-oro-y-aceites-esenciales-relajantes-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 572",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 573",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-espalda-cuello-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 574",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 575",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 576",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-anticelulitis-masaje-envoltura-anticelulitica/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 577",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-terapeutico-deportivo-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 578",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-rosa-terapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 579",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/vedic-massage-60-min-thai-room/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 580",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/futura-mama-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 581",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-hammam/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 582",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-de-aloe-vera-y-hojas-de-platanera/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 583",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/detox-oxigenante-60min-biologique-recherche/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 584",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-en-pareja-60-minutos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 585",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-relajante-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 586",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-day/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 587",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-spa-almuerzo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 588",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-residente-canario/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 589",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-spa-brunch-by-bahia-del-duque/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 590",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-energizante-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 591",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-adultos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 592",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 593",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-aromaterapia-25-min-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 594",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-kobido-emperatriz/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 595",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/ritual-flash/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 596",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/masaje-shi-zen-relax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 597",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/circuito-spa-ninos-4-15-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 598",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 599",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 600",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-115-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 601",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/padres-e-hijos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 602",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/luz-del-mediterraneo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 603",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-mediterranea/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 604",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-140-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 605",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/experiencia-relax-junior/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 606",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/aire-de-hadas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 607",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-ritual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 608",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-masaje-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 609",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/masaje-personalizado-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 610",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-masaje-25/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 611",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/masaje-personalizado-25/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 612",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/passage-du-silence/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 613",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-30min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 614",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relax-massage-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 615",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/love-massage-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 616",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 617",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 618",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa-gastronomia-masaje-cocktail/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 619",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa-masaje-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 620",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 621",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 622",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa-gastronomia-cocktail/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 623",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-90/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 624",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-60/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 625",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/facial-express/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 626",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/ritual-piel-de-seda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 627",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-relajante-calma/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 628",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/ritual-detox/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 629",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-masaje-calma-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 630",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-masaje-calma/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 631",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-ritual-de-argan-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 632",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-ritual-de-argan/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 633",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 634",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 635",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-de-espalda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 636",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-indio-de-cabeza/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 637",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-express/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 638",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-adulto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 639",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 640",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 641",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-adulto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 642",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-cocktail-en-skybar-adulto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 643",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/limpieza-hidratacion-con-acido-hialuronico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 644",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/maderoterapia-radiofrecuencia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 645",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/ritual-para-la-novia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 646",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-spa-vip/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 647",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-personas-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 648",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-personas-no-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 649",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-de-noche-para-2-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 650",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-dos-no-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 651",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-1-persona-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 652",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-2-personas-no-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 653",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-1-persona-no-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 654",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-de-60-minutos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 655",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-mimate-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 656",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/maderoterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 657",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cavitacion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 658",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/envoltura-canaria/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 659",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-spa-vip-para-2-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 660",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-1-persona-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 661",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-persona-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 662",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-30-minutos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 663",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-respira-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 664",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-dos-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 665",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-romantica-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 666",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/presoterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 667",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/silicium-lift/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 668",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/body-sculpt/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 669",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-mimate-no-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 670",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-2-personas-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 671",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-2-personasresidente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 672",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-1-persona-no-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 673",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-2-personas-no-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 674",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/domingo-familiar-domingos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 675",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-0-5-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 676",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 677",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-6-12-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 678",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/viernes-juvenil/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 679",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-de-noche/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 680",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-vip/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 681",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-13-17-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 682",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cumpleanos-con-picoteo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 683",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/aqua-baby/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 684",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 685",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-no-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 686",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/pack-ritual-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 687",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/desconexion-con-spa-y-belleza-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 688",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/bienestar-con-spa-y-ritual-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 689",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/piscina-climatizada/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 690",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/ritual-aires-del-mediterraneo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 691",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 692",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/experiencia-wellness/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 693",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/circuito-masaje-especial-egipto-para-2-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 694",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/especial-convento-las-claras/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 695",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/masaje-relax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 696",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/programa-relax-circuito-vinoterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 697",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/masaje-balines/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 698",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/circuito-termal-gastronomia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 699",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/masaje-relax-total/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 700",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/circuito-termal-infantil/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 701",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 702",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/circuito-termal-premium/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 703",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/circuito-termal-privado-para-2-4-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 704",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/masaje-4-manos-fusion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 705",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/envoltura-masaje-podal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 706",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/especial-quiromasaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 707",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-piernas-ligeras/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 708",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/aromaterapia-80/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 709",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/paquete-antiestres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 710",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-sueco/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 711",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/aromaterapia-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 712",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/4-hrs-spa-experience-pack-gourmet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 713",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-two-by-two/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 714",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-futuras-mamas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 715",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/envoltura-corporal-de-aloe-vera/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 716",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/envoltura-con-fango/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 717",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-quirogolf/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 718",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/capricho-de-chocolate/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 719",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/stone-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 720",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/4-hrs-spa-experience/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 721",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/paquete-belleza/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 722",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/paquete-spa-experience/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 723",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-completo-con-aromaterapia-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 724",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/oasis-de-serenidad-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 725",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-parcial-relajante-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 726",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/oasis-de-serenidad/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 727",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/especial-reino-de-hungria-doble/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 728",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/reflexologia-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 729",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-de-spa-externo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 730",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-spa-facial-hidra/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 731",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-spa-peeling-corporal-ritual-sur-de-canarias/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 732",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-completo-con-aromaterapia-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 733",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/especial-reino-de-hungria/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 734",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/ritual-reino-de-hungria-2-horas-de-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 735",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/facial-equilibrium/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 736",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-parcial-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 737",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-cuerpo-completo-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 738",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-facial-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 739",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/ritual-oriental-balines-2-horas-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 740",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 741",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-balines/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 742",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/shiatsu/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 743",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-de-cuello-2-horas-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 744",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/ritual-reino-de-egipto-2-horas-de-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 745",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/peeling-clasico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 746",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-2-dias/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 747",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-sales/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 748",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-algas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 749",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-de-la-polinesia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 750",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-esencias/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 751",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-indonesio/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 752",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/envoltura-hidratante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 753",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/lomi-lomi-80m/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 754",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/aromatico-80m/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 755",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/thai-50m/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 756",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/piedras-volcanicas-50m/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 757",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-oriental/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 758",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-canario/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 759",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-dragon-de-oro/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 760",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/hidroterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 761",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-mahana/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 762",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-local-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 763",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-hindu/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 764",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-relajacion-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 765",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 766",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/spa-para-2-adultos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 767",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 768",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-sueco/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 769",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/ritual-evasion-de-los-sentidos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 770",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-reconfortante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 771",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/menu-om-in-love-postrecava/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 772",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-two-by-two/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 773",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/velada-romantica-thalasso-experience-4-horas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 774",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pack-algas-adelgazante-corporal-y-remineralizante-facial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 775",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/aromaterapia-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 776",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/atardecer-oceanico-ocean-view/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 777",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/thalasso-experience-3h-menu-nature-corner/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 778",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/thalasso-experience-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 779",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/thalasso-experience-1-dia-menu-nature-corner/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 780",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-futuras-mamas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 781",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/capricho-de-chocolate/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 782",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/piscina-hidroterapia-vista-mar-ocean-view-30min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 783",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/paraiso-oceanico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 784",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/delicias-del-atlantico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 785",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-antiestres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 786",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/stone-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 787",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/rhassoul/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 788",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-deep-tissue/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 789",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-masaje-y-gastronomia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 790",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-de-aromaterapia-50-minutos-y-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 791",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/las-dunas-facial-deluxe-y-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 792",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-y-desayuno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 793",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 794",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-y-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 795",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-con-piedras-calientes-80-minutos-y-circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 796",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/vip-spa-suite/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 797",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 798",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-ritual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 799",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/aire-de-hadas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 800",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/mountain-flower/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 801",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-tailandes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 802",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-masaje-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 803",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-personalizado-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 804",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-masaje-25/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 805",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-personalizado-25/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 806",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/passage-du-silence/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 807",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/olive-flower-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 808",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-marruecos-1h-45/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 809",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-masaje-aromatico-50-y-piscina-spa-2h/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 810",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-aromatico-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 811",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-bangalore-80-y-piscina-spa-2h/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 812",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-marruecos-105-y-piscina-spa-2h/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 813",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-bangalore-1h-20/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 814",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-java-45-y-piscina-spa-2h/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 815",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-java-45/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 816",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-domingos-de-piscina-spa-y-brunch/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 817",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-agua-y-relax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 818",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-basic/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 819",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-premium/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 820",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-piscina-spa-2h-acceso/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 821",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/especial-parejas-2h-piscina-spa-masaje-aromatico-25/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 822",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-futura-mama-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 823",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-ayurvedico-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 824",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 825",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 826",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 827",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 828",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 829",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-2-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 830",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-2-adultos-1-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 831",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-1-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 832",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-deep-tisue/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 833",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-bienestar-masaje-relajante-50-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 834",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-romance-de-sol-y-luna-circuito-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 835",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 836",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/oasis-de-serenidad-circuito/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 837",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-30min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 838",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-massage-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 839",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/love-massage-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 840",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 841",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/experiencia-romantica-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 842",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/ritual-cleopatra-bano-aromatico-masaje-relajante-y-masaje-craneal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 843",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-2-adultos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 844",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 845",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-en-familia-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 846",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pura-elegancia-by-alqvimia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 847",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/terapia-lomi-lomi/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 848",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-personalizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 849",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-afterwork-masaje-coctel/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 850",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/masaje-facial-kobido/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 851",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/majestic-morning-up-desayuno-zona-de-hidroterapia-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 852",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/friendly-majestic-duo-moments/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 853",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-quierete/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 854",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia-facial-antiedad-cena/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 855",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/diamond-energy-by-natura-bisse/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 856",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/oriental-touch-signature-treatment/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 857",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/flash-facial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 858",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/c-vitamin-coctel-by-natura-bisse/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 859",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/the-cure-by-natura-bisse/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 860",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/zona-de-hidroterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 861",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/momento-romantico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 862",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/pack-majestic-morning-up/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 863",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/toque-de-calma-by-alqvimia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 864",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-paradisus/evasion-belleza-con-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 865",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-paradisus/la-fuente-del-bienestar-con-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 866",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-paradisus/vitalidad-absoluta-con-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 867",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-paradisus/spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 868",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-boutique-hotel-comtal-empuries/circuito-spa-experiencia-gastronomica-para-dos-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 869",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-boutique-hotel-comtal-empuries/circuito-spa-masaje-para-dos-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 870",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-thai-oil/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 871",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-facial-de-oriente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 872",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/drenaje-linfatico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 873",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/spa-privado-para-dos-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 874",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-deportivo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 875",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/pack-spa-experience-para-dos-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 876",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 877",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-prenatal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 878",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-geo-serawa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 879",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-hawaiano/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 880",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/termal-experience/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 881",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/experiencia-relax-termal-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 882",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/circuito-spa-inagua-huespedes-cordial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 883",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/ritual-pre-sun/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 884",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/reino-de-egipto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 885",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/oasis-de-serenidad/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 886",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/circuito-spa-inagua/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 887",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/futuras-mamas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 888",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/envoltura-de-frutas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 889",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/especial-reino-de-hungria/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 890",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tratamiento-facial-sensacion-mar/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 891",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-shiatsu/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 892",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-tailandes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 893",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/espalda-y-piernas-terapeutico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 894",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 895",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-ayurveda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 896",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/reflexologia-55min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 897",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/peeling-corporal-con-sales-del-mar-muerto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 898",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-cuerpo-completo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 899",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 900",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-y-masaje-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 901",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 902",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-con-gastronomia-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 903",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-infantil/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 904",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-con-gastronomia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 905",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/suite-time-for-two/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 906",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/suite-time-for-two-luxury/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 907",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/smooth-operator/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 908",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/wrap-remix/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 909",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-desayuno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 910",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 911",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/inner-peace/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 912",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 913",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/synchronicity-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 914",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/deep-tissue-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 915",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/day-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 916",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-cena-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 917",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/acceso-circuito-spa-cliente-residente/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 918",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/its-your-time/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 919",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/jelly-gum-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 920",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/face-the-music/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 921",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-descarga-45min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 922",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/hidradermis-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 923",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-60-min-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 924",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-facial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 925",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-45-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 926",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 927",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 928",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-y-templarium/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 929",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/ritual-ashanti-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 930",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-especial-parejas-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 931",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-ritual-parejas-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 932",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-en-pareja-masaje-de-30min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 933",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/chocolaterapia-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 934",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-brunch/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 935",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/programa-antiestres-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 936",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-romantico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 937",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 938",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-120-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 939",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-90-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 940",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/time-ritual-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 941",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/serenity-full-body-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 942",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/sport-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 943",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/detox-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 944",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/deep-tissue-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 945",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-condal-mar/digital-wellness-escape/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 946",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 947",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-nino-90/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 948",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 949",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hawaiian-lomi-lomi-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 950",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/masaje-infantil/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 951",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-adulto-90/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 952",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/abhyanga/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 953",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/wellness-ritual-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 954",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 955",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/ritual-de-la-vela-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 956",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/prenatal-y-posnatal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 957",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-desayuno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 958",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hydraluronic/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 959",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/1-dia-en-spa-diamante-con-masaje-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 960",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/the-essential/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 961",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/piscina-termal-entrada-adultos-externos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 962",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/masaje-relajante-aromaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 963",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/masaje-terapeutico-personalizado-55-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 964",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/hydra-green-treatment-scens/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 965",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/envolturas-castilla-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 966",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/masaje-valbuena-de-piedras-volcanicas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 967",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/quiromasaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 968",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/piscina-termal-entrada-ninos-externos-0-a-12-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 969",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/wood-therapy/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 970",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/ritual-belleza-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 971",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/ritual-ribera-del-duero/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 972",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/ritual-del-agua/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 973",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/ritual-de-aromaterapia-para-dos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 974",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-facial-kobido/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 975",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/la-utima-experiencia-de-belleza-esse/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 976",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-champi/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 977",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-abhyanga/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 978",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/sprunch-para-6-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 979",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-y-corporal-geisha/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 980",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 981",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/masaje-ayurvedico-para-embarazadas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 982",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado-masaje-relajante-o-descontracturante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 983",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-detox/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 984",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-con-vitamina-c/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 985",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-y-corporal-de-chocolaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 986",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-corporal-de-exfoliacion-e-hidratacion-tropical/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 987",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado-desayuno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 988",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 989",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-corporal-al-andalus/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 990",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/clase-yoga-pilates-qigong-2-pax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 991",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/clase-yoga-pilates-qigong-1-pax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 992",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/facial-hidratacion/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 993",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/abama-total-wellness/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 994",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-bio-energetico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 995",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-presion-profunda-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 996",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/facial-pureza-marina/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 997",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/pura-experiencia-detox/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 998",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/ritual-canario-de-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 999",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-sandara-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1000",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-balines/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1001",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/experiencia-ayurvedica/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1002",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/especial-black-friday-masaje-balines/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1003",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-alojado-2-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1004",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-externo-2-personas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1005",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-2-adultos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1006",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1007",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-en-familia-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1008",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-masaje-relajante-y-cava-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1009",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-y-masaje-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1010",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-mediodia-de-lunes-a-viernes-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1011",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/kobido-o-lifting-facial-japones/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1012",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1013",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-3/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1014",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1015",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-cena-de-lunes-a-viernes-noches/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1016",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-120-min-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1017",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-cena-de-lunes-a-viernes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1018",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bano-de-hidromasaje-envoltura-de-algas-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1019",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1020",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/jacuzzi-masaje-en-pareja-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1021",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/ritual-purificante-y-renovador-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1022",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-expres-con-acido-hialuronico-i-q10-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1023",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/escapada-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1024",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-gastro-fin-de-semana-y-festivos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1025",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-abyhanga/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1026",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-gastro-fines-de-semana-y-festivos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1027",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1028",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1029",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-viernes-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1030",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1031",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-y-gastro-almuerzo-de-lunes-a-viernes-mediodia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1032",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-lunes-a-viernes-festivos-no-incluidos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1033",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-breakfast-sabados-domingos-y-festivos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1034",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/luxury-private-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1035",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1036",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/drenaje-linfatico-de-50-circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1037",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/momentos-complices-adulto-nino-a-6-13-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1038",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-subacuatico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1039",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/masaje-terapeutico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1040",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/masaje-relajante-45-min-comida/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1041",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/exfoliante-corporal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1042",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/masaje-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1043",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/masaje-localizado-20-min-cena/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1044",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/circuito-spa-2h/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1045",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/elke-spa-hotel/circuito-spa-1h/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1046",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-aguas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1047",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-aguas-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1048",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-luna-llena/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1049",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1050",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1051",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/ritual-sensacion-oriental-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1052",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/circuito-spa-senda-de-los-sentidos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1053",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/limpieza-profunda-tratamiento-contorno-de-ojos-is-clinical/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1054",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/despertar-laguna/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1055",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-relax-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1056",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suite-royal-therapy/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1057",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suenos-mediterraneos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1058",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-25min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1059",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-localizado-20/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1060",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-premium/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1061",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-peeling/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1062",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/reflexologia-podal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1063",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-pureza/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1064",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-sunset-in-egypt/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1065",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-80-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1066",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1067",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1068",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1069",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-romance-de-sol-y-luna/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1070",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1071",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-deep-tisue-80-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1072",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-deep-tisue-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1073",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-capricho-de-los-dioses/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1074",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-esplendor-radiante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1075",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1076",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1077",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-a-las-vinas-de-la-toscana/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1078",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-30min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1079",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/relax-massage-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1080",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/love-massage-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1081",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-siargao/serenity-vitality-ritual-55min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1082",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-algas-intensive/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1083",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-teide-magico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1084",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-secreto-de-la-india/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1085",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-con-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1086",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-descontracturante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1087",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-cava-y-chocolate/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1088",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-afrodita/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1089",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-personalizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1090",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-ayurveda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1091",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1092",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-tratamiento-facial-basico/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1093",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-reflexologia-podal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1094",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1095",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/ritual-alma-de-naiad-para-el/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1096",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/ritual-alma-de-naiad-para-ella/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1097",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/oasis-de-la-serenidad-by-alqvimia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1098",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/tratamiento-essentially-beautiful-by-alqvimia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1099",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-m-v/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1100",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/gastro-wellness-experience-cena-spa-mascarilla/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1101",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wine-wellness-para-dos-especial-navidad/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1102",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-christmas-day-pass-especial-navidad/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1103",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-en-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1104",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-brunch-experience-desayuno-buffet-spa-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1105",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1106",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/day-pass-masaje-cocktail/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1107",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1108",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1109",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-50/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1110",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1111",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-l-v/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1112",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-s-d/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1113",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-s-d/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1114",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-m-v/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1115",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-s-d/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1116",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-best-sellers-christmas-edition-especial-navidad/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1117",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energizer-40-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1118",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/detox-40-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1119",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/swedish-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1120",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/deep-tissue/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1121",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/stone-therapy/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1122",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/foot-reflexology/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1123",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/booster-vitamin-c/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1124",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/essences-senses/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1125",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energizer-55-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1126",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/detox-55-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1127",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/circuito-hydrowellness/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1128",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/love-secret/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1129",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-happy-mum/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1130",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-deep-tissue/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1131",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piedras-calientes-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1132",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/entrada-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1133",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/entrada-spa-nino-5-15-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1134",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1135",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-antiestres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1136",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-antiestres-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1137",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/body-care-uli-massage-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1138",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-baby-relax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1139",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/body-care-uli-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1140",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/aquae-private-experience-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1141",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/romantic-private-experience-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1142",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-private-experience-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1143",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/termal-experience/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1144",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/masaje-general/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1145",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-legs-relief/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1146",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/relax-renew-massage-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1147",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/hot-stones/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1148",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/ayurvedic-shirodhara-abhyanga/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1149",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/peeling-massage-cocooning/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1150",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-corporal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1151",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/signature-mediterranean-lifting-anti-aging/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1152",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/ayurvedic-pinda-herbal-therapy-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1153",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/precious-pearl/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1154",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/anti-stress-back-remedy/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1155",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/mediterranean-bliss-face-body/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1156",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-facial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1157",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/candle-night/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1158",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/circuito-spa-2-horas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1159",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/malaga/mood-spa/deep-tissue-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1160",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-cabina-vip-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1161",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/facial-express/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1162",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-maderoterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1163",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-air-bio-spa-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1164",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/air-bio-spa-privado-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1165",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-privado-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1166",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-110/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1167",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1168",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-85-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1169",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-110-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1170",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-85/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1171",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-con-oasis-bio-120-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1172",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-familiar-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1173",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1174",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1175",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-spa-privado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1176",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-familiar-privada-padres-e-hijos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1177",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-cabina-vip/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1178",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-bio-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1179",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-bio-spa-luxury/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1180",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-belleza-oriental-duo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1181",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen-duo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1182",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience-duo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1183",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1184",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1185",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-embarazadas-nuad-kon-thong-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1186",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-de-bambu-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1187",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/envoltura-natural-para-una-piel-perfecta-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1188",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-con-aceite-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1189",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-4-manos-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1190",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-pareja-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1191",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/circuito-spa-wellness/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1192",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-tradicional-pareja-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1193",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1194",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/manicura-senza/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1195",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-pareja/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1196",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-con-masaje-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1197",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-con-masaje-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1198",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-reflexologia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1199",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/facial-expres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1200",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/facial-calmante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1201",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1202",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-muscular-profundo-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1203",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-relajante-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1204",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1205",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-prenatal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1206",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1207",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-muscular-profundo-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1208",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/exfoliacion-corporal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1209",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/masaje-infantil/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1210",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/mudejar/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1211",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/terapia-geotermal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1212",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/circuito-de-contrastes-patio-mudejar/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1213",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/elixir-reparador/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1214",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/piscina-termal-entrada-adultos-externos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1215",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/piscina-termal-entrada-ninos-externos-0-a-12-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1216",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/descontracturante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1217",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/belleza-para-dos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1218",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/desestresante-con-barro/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1219",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/abhyanga/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1220",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/prenatal-y-posnatal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1221",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/esencias-y-sentidos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1222",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/circuito-privado-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1223",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/el-escape-perfecto/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1224",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/custom/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1225",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/programa-zen-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1226",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/hydraluronic/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1227",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/signature-cookbook/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1228",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/the-essential/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1229",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/wellness-ritual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1230",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-con-circuito-termal-120min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1231",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/circuito-hydrowellness/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1232",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas-masaje-55-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1233",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1234",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-circuito-de-aguas-masaje-55-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1235",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-circuito-de-aguas-masaje-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1236",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas-masaje-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1237",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/after-work/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1238",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/est-facial-hidratante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1239",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-happy-mum/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1240",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-deep-tissue/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1241",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/body-care-uli-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1242",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-baby-relax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1243",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/love-secret/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1244",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1245",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-antiestres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1246",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-60-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1247",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/pack-relax-health-privee-circuito-privado-masaje-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1248",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1249",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-deep-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1250",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas-masaje-relax-60/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1251",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-de-aguas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1252",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-oxigen/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1253",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/ritual-go-sun-passion-fruit/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1254",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-30/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1255",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/masaje-relax-health-60/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1256",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/circuito-privado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1257",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-cromoterapia-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1258",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/deep-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1259",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/sesion-reiki-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1260",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/rituales-diosas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1261",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/antiestres/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1262",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-signature-massage/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1263",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/cocoon-therapy-envolturas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1264",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/stone-therapy-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1265",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-prodigy/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1266",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/serenity/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1267",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1268",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/essences-senses/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1269",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/booster-vitamin-c/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1270",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-prodigy-force/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1271",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-intense/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1272",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/excellence-marine-collagen-lift/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1273",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ayurveda-royal-thai-poultice/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1274",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1275",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1276",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/peeling/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1277",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/resugence/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1278",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/day-pass-spa-masaje-25min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1279",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-masaje-almuerzo/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1280",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-signature-ritual/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1281",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/circuito-hydrowellness-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1282",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-masaje-almuerzo-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1283",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/bono-regalo-50e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1284",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/bono-regalo-150e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1285",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/bono-regalo-100e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1286",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/bono-regalo-200e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1287",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/bono-regalo-300e/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1288",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/spa-in-radisson-blu-resort-75-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1289",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/spa-privado-y-masaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1290",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/contractura-muscular-localizado/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1291",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-aromasoul-aromaterapia-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1292",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/romance-spa-para-dos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1293",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-futuras-mamas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1294",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/mi-mama-y-yo-especial-ninas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1295",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/ritual-canario-80-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1296",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/envoltura-con-aloe-vera/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1297",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/pack-para-dos-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1298",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-gominola/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1299",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/mi-papa-y-yo-especial-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1300",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-de-espalda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1301",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-a-su-eleccion-50min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1302",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-ayurveda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1303",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-lomi-lomi/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1304",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa-masaje-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1305",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1306",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa-desayuno-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1307",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa-cena-buffet/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1308",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/girona/spa-la-collada/circuito-spa-infantil-hasta-12-anos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1309",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-con-masaje-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1310",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-con-masaje-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1311",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-reflexologia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1312",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-express-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1313",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-calmante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1314",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-muscular-profundo-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1315",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1316",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-relajante-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1317",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1318",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-prenatal/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1319",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-para-ninos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1320",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-muscular-profundo-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1321",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-y-masaje-aromaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1322",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1323",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-buffet-libre-con-bebidas-incluidas-1/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1324",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-masaje-y-banera-privada-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1325",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1326",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/date-un-capricho-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1327",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/express-massage-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1328",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/lulur-ritual-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1329",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/choccolat-massage-scrub-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1330",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/ritual-candle-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1331",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/magic-honey-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1332",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/acceso-al-spa-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1333",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/barcelona/spazio-nyxpert-sant-joan-despi/back-soul-acceso-al-spa/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1334",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/paquete-experience-aromaterapia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1335",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/dia-en-la-gloria/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1336",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/masaje-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1337",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/momentos-romanticos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1338",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/masaje-50-min-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1339",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/masajes-al-aire-libre-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1340",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/harmony-experience/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1341",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/paquete-especial-esencial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1342",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/masaje-relax-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1343",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/masaje-relax-25-min-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1344",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/ayurveda-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1345",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/ayurveda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1346",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/paquete-thalasso-especial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1347",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/niagara/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1348",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/circuito-relax-1-entrada/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1349",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/neurosedante-con-aromaterapia-relajante/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1350",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/oxigenacion-facial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1351",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/bamboo-scrub/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1352",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/peeling-sales-envoltura-de-chocolate/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1353",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-lomi-lomi/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1354",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-de-espalda-y-cuello-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1355",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-spa-masaje-y-buffet-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1356",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-spa-y-buffet-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1357",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-relax-para-2/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1358",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/diamond-rose-experiencia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1359",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/oro-y-aceites-esenciales-relajantes-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1360",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/piedras-calientes/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1361",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/chocolate-relajante-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1362",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/ayurveda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1363",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/facial-ritual-of-beauty-30-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1364",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/banera-hidromasaje/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1365",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/bonos-masaje-relax-25-min-10sesiones/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1366",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/bono-masaje-50-min-10-sesiones/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1367",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/bono-fangoterapia-10-sesiones/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1368",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/tratamiento-hyalu-procollageno/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1369",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/tratamiento-nu-skin/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1370",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/paquete-especial-esencial/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1371",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/peeling-cristal-con-flores/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1372",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/un-dia-en-la-gloria/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1373",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/masaje-al-aire-libre/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1374",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/masaje-50-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1375",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/masaje-50-min-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1376",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/piscina-puesta-en-forma/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1377",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/momentos-romanticos/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1378",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/thalasso-experiencia/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1379",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/piscina-puesta-en-forma-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1380",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/fangoterapia-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1381",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/thalgo-relax/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1382",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/masaje-relax-25-min/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1383",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/masaje-relax-25-min-parejas/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.name ?? "Service landing: 1384",
    path: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/ayurveda/",
    props: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.props ?? false,
    meta: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta || {},
    alias: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.alias || [],
    redirect: ServiceDetail_46pageOcORPyDdtxOqlF99oA_45CU7s2vjzl16xTEePS0nGOmksMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-9c3d8058-a758-40b9-80b6-655494d8f882/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]